import React from 'react'

import { MainLayout } from '@common/layouts'
import { Newsletter } from '@common/components'

import Cannabis101 from './components/Cannabis101'
import Hero from './components/Hero'
import Highlights from './components/Highlights'
import LatestArticles from './components/LatestArticles'
import Messaging from './components/Messaging'
import QuestionsAndAnswers from './components/QuestionsAndAnswers'
import Strains from './components/Strains'

const componentsMap = {
  Cannabis101: (props) => <Cannabis101 {...props} />,
  Highlights: (props) => <Highlights {...props} />,
  LatestArticles: (props) => <LatestArticles {...props} />,
  Messaging: (props) => <Messaging {...props} />,
  QuestionsAndAnswers: (props) => <QuestionsAndAnswers {...props} />,
  Strains: (props) => <Strains {...props} />,
}

const LandingPage = ({ data, pageContext }) => {
  const { hero, body } = data.strapiLandingPage

  return (
    <MainLayout>
      <Hero data={hero} heroImageHexa={pageContext.heroImageHexa} />

      {body.map(
        ({ componentName, ...rest }) =>
          componentsMap[componentName] &&
          componentsMap[componentName]({
            ...rest,
            ...pageContext,
            latestArticles: data.latestArticles,
            questionDefaultImage: data.questionDefaultImage,
            key: componentName,
          })
      )}

      <Newsletter.LandingPage />
    </MainLayout>
  )
}

export default LandingPage
