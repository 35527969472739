import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'

import SeeAllLink from './SeeAllLink'
import Title from './Title'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-6 mb-16 xl:max-w-screen-xl`}
`

const List = styled.ul`
  ${tw`grid grid-cols-1 gap-12`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-3)`}
`

const ListItem = styled.li`
  ${tw`w-full`}
`

const LatestArticles = (props) => {
  const {
    title,
    quantity,
    latestArticles: { edges: list },
  } = props

  return (
    <Wrapper>
      <Title>{title || 'Top Stories'}</Title>
      <List>
        {list.slice(0, quantity).map(({ node: article }) => (
          <ListItem key={article.id}>
            <Link
              to={`/articles/${article.slug}`}
              title={`Article: ${article.title}`}
              tw="block overflow-hidden cursor-pointer"
            >
              {article.image && (<Img
                tw="w-full mb-6"
                fluid={{
                  ...article.image.childImageSharp.fluid,
                  aspectRatio: 380 / 255,
                }}
                alt={article.title}
              />)}
              <h2 tw="text-lg text-gray-900 tracking-tight leading-6 mb-2">
                {article.title}
              </h2>
              <p tw="text-sm text-gray-800">
                {article.childMarkdownRemark.excerpt}
              </p>
            </Link>
          </ListItem>
        ))}
      </List>
      <SeeAllLink href="/latest-articles" title="See Latest Articles">
        See All Articles
      </SeeAllLink>
    </Wrapper>
  )
}

export default LatestArticles
