import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

import SeeAllLink from './SeeAllLink'
import Title from './Title'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-6 mb-16 xl:max-w-screen-xl`}
`

const List = styled.ul`
  ${tw`grid grid-cols-1 gap-12`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-4)`}
`

const ListItem = styled.li`
  ${tw`w-full`}
`

const Strains = ({ title = 'Strains', strains, questionDefaultImage }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <List>
        {strains
          .sort(() => 0.5 - Math.random())
          .map((strain) => (
            <ListItem key={strain.id}>
              <Link
                to={`/strains/${strain.slug}`}
                title={`Strain: ${strain.name}`}
                tw="block overflow-hidden cursor-pointer"
              >
                <Img
                  tw="w-full mb-6"
                  fluid={{
                    ...(strain.image
                      ? strain.image.childImageSharp.fluid
                      : questionDefaultImage.childImageSharp.fluid),
                    aspectRatio: 380 / 255,
                  }}
                  alt={strain.name}
                />
                <h2 tw="text-lg text-gray-900 tracking-tight leading-6 mb-2">
                  {strain.name}
                </h2>
              </Link>
            </ListItem>
          ))}
      </List>
      <SeeAllLink href="/strains" title="See all strains">
        See All Strains
      </SeeAllLink>
    </Wrapper>
  )
}

export default Strains
