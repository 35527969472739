import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import tw, { styled } from 'twin.macro'

import SeeAllLink from './SeeAllLink'
import Title from './Title'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-6 mb-16 xl:max-w-screen-xl`}
`

const List = styled.ul`
  ${tw`grid grid-cols-1 gap-12`}
  ${tw`md:(grid-cols-2)`}
  ${tw`lg:(grid-cols-4)`}
`

const ListItem = styled.li`
  ${tw`w-full`}
`

const QuestionsAndAnswers = ({
  title = 'Q & A - Your Cannabis Advisor',
  questions,
  questionDefaultImage,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <List>
        {questions
          .sort(() => 0.5 - Math.random())
          .map((question) => (
            <ListItem key={question.id}>
              <Link
                to={`/questions-and-answers/${question.slug}`}
                title={`Question: ${question.title}`}
                tw="block overflow-hidden cursor-pointer"
              >
                <Img
                  tw="w-full mb-6"
                  fluid={{
                    ...(question.image
                      ? question.image.childImageSharp.fluid
                      : questionDefaultImage.childImageSharp.fluid),
                    aspectRatio: 380 / 255,
                  }}
                  alt={question.title}
                />
                <h2 tw="text-lg text-gray-900 tracking-tight leading-6 mb-2">
                  {question.title}
                </h2>
              </Link>
            </ListItem>
          ))}
      </List>
      <SeeAllLink href="/questions-and-answers" title="See all Q&A">
        See All Q&A
      </SeeAllLink>
    </Wrapper>
  )
}

export default QuestionsAndAnswers
