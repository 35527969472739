import React from 'react'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import tw, { styled } from 'twin.macro'

import SeeAllLink from './SeeAllLink'
import Title from './Title'

const Wrapper = styled.section`
  ${tw`flex flex-col w-full mx-auto px-6 mb-16 xl:max-w-screen-xl`}
  ${tw`md:(mb-24)`}
`

const Content = styled.ul`
  ${tw`grid grid-cols-1 gap-8`}
  ${tw`md:(grid-rows-2 grid-cols-2 gap-4)`}
`

const GridItem = styled.li`
  ${tw`h-72 w-full bg-black`}
  ${({ isFirst }) => isFirst && tw`md:(h-auto row-span-2)`}
`

const GridItemBg = styled(BackgroundImage)`
  ${tw`flex flex-col w-full h-full p-8 justify-end`}
`

const GridItemTitle = styled.h2`
  ${tw`text-white text-lg tracking-tight leading-7`}
  ${tw`md:(text-xl)`}
`

const Highlights = ({ title = 'Anxiety, Chronic Pain, Sleep', articles }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>
        {articles
          .sort(() => 0.5 - Math.random())
          .slice(0, 3)
          .map((article, index) => {
            const backgroundFluidImageStack = [
              `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75))`,
              article.image.childImageSharp.fluid,
            ]

            return (
              <GridItem key={`highlight-${index}`} isFirst={index === 0}>
                <Link to={`/articles/${article.slug}`} tw="flex h-full">
                  <GridItemBg
                    fluid={backgroundFluidImageStack}
                    backgroundColor={`#000`}
                  >
                    <GridItemTitle>{article.title}</GridItemTitle>
                  </GridItemBg>
                </Link>
              </GridItem>
            )
          })}
      </Content>
      <SeeAllLink href="/highlights" title="See all Highlights Articles">
        See All Articles
      </SeeAllLink>
    </Wrapper>
  )
}

export default Highlights
