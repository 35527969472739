import React from 'react'
import { graphql } from 'gatsby'

import { LandingPage } from '@common/build'

export const query = graphql`
  query StrapiQuery($id: Int) {
    strapiLandingPage: strapiLandingPageCanada(strapiId: { eq: $id }) {
      hero {
        title
        linkTitle
        linkUrl
        extraText
        images {
          hexaColor
          desktopImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mobileImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      body {
        quantity
        title
        linkTitle
        linkUrl
        componentName
        articles {
          slug
          id
          title
          content
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        questions {
          slug
          id
          title
          image {
            childImageSharp {
              fluid(quality: 80, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        strains {
          slug
          id
          name
          image {
            childImageSharp {
              fluid(quality: 80, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        images {
          hexaColor
          desktopImage {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          mobileImage {
            childImageSharp {
              fluid(quality: 90, maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    latestArticles: allArticle(
      limit: 9
      filter: { belongsTo: { in: ["CA"] } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          content
          childMarkdownRemark {
            excerpt(pruneLength: 60)
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    questionDefaultImage: file(
      relativePath: { eq: "question-default-image.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const LandingPageTemplate = (props) => <LandingPage {...props} />

export default LandingPageTemplate
