import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const Center = styled.div`
  ${tw`relative mx-auto max-w-screen-xl mb-16`}
  ${tw`md:(h-auto)`}
`

const StyledBackgroundImage = styled(BackgroundImage)`
  ${tw`bg-right-bottom flex items-start justify-center w-full pb-10`}
  ${tw`md:(h-auto py-0 items-center justify-start h-116)`}
  height: 100vw;
`

const TextWrapper = styled.div`
  ${tw`flex flex-col items-center justify-between z-10 px-4 pt-12 text-white`}
  ${tw`md:(absolute inset-0 h-full ml-12 items-start justify-center)`}
`

const Title = styled.h1`
  ${tw`text-3xl font-semibold tracking-tight leading-tight mb-6 text-center`}
  ${tw`md:(text-4xl max-w-md text-left)`}
  ${tw`lg:(max-w-xl)`}
`

const Button = styled(Link)`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-center text-sm font-semibold p-2 px-4 rounded text-gray-800 tracking-wider uppercase border border-transparent outline-none transition-colors duration-200 shadow`}
  ${tw`hover:(text-black bg-white border-white opacity-100)`}
  ${tw`md:(h-12)`}
`

const ExtraText = styled.h3`
  ${tw`text-xl tracking-tight leading-tight my-6 text-center`}
  ${tw`md:(text-xl max-w-xl text-left)`}
  ${tw`lg:(max-w-2xl)`}

  & > a {
    ${tw`underline`}
  }
`

const Hero = ({ data, heroImageHexa }) => {
  const { desktopImage, mobileImage, hexaColor } = data.images.find(
    ({ hexaColor }) => hexaColor === heroImageHexa
  )

  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <section style={{ backgroundColor: hexaColor }}>
      <Center>
        <TextWrapper>
          <Title>{data.title}</Title>
          <Button
            to={data.linkUrl}
          >
            {data.linkTitle}
          </Button>
          {data.extraText && (
            <ExtraText
              dangerouslySetInnerHTML={{
                __html: data.extraText,
              }}
            />
          )}
        </TextWrapper>
        <StyledBackgroundImage
          Tag="section"
          fluid={sources}
          backgroundColor={hexaColor}
        />
      </Center>
    </section>
  )
}

export default Hero
