import React from 'react'
import { Link } from 'gatsby'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import BackgroundImage from 'gatsby-background-image'
import removeMarkdown from 'remove-markdown'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'

import SeeAllLink from './SeeAllLink'
import Title from './Title'

SwiperCore.use([Pagination])

const Wrapper = styled.section`
  ${tw`w-full px-6 mb-16 bg-black pt-20 pb-8`}
`

const Content = styled.div`
  ${tw`flex flex-col w-full`}
`

const Swipe = styled(Swiper)`
  ${tw`relative w-full h-full mx-auto overflow-hidden p-0`}
  ${css`
    .swiper-wrapper {
      ${tw`relative flex w-full h-full box-content pb-24`}
    }
    .swiper-slide {
      ${tw`relative w-full h-full flex-shrink-0`}
      transition-property: transform;
    }
    .swiper-pagination {
      ${tw`absolute w-full left-0 text-center z-10 transition transition-opacity duration-300`}
      bottom: 10px;
    }
    .swiper-pagination-bullet {
      ${tw`w-2 h-2 rounded-full bg-gray-500 inline-flex cursor-pointer mx-1`}
      &-active {
        ${tw`bg-primary`}
      }
    }
  `}
`

const ArticleBg = styled(BackgroundImage)`
  padding-top: 100%;
  ${tw`relative h-full w-full `}
`

const ArticleContent = styled.div`
  ${tw`absolute inset-0 flex flex-col justify-end text-white text-center p-8`}
`

const Cannabis101 = ({ title = 'Cannabis 101', articles }) => {
  return (
    <Wrapper>
      <Title isWhite>{title}</Title>
      <Content>
        <Swipe
          slidesPerView={1}
          spaceBetween={50}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          pagination={{ clickable: true }}
          centeredSlides
          loop
        >
          {articles
            .sort(() => 0.5 - Math.random())
            .map((article) => {
              const backgroundFluidImageStack = [
                `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.60))`,
                { ...article.image.childImageSharp.fluid, aspectRatio: 1 },
              ]

              return (
                <SwiperSlide key={`swiper-${article.id}`}>
                  <Link to={`/articles/${article.slug}`} tw="flex h-full">
                    <ArticleBg
                      fluid={backgroundFluidImageStack}
                      backgroundColor={`#000`}
                    >
                      <ArticleContent>
                        <h2 tw="text-lg tracking-tight leading-6 mb-2 font-semibold lg:text-xl">
                          {article.title}
                        </h2>
                        <p tw="text-sm">
                          {removeMarkdown(article.content).replace(
                            /^(.{60}[^\s]*).*/s,
                            '$1...'
                          )}
                        </p>
                      </ArticleContent>
                    </ArticleBg>
                  </Link>
                </SwiperSlide>
              )
            })}
        </Swipe>
        <SeeAllLink
          textWhite
          href="/tag/cannabis-101"
          title="See all Cannabis 101 Articles"
        >
          See All Articles
        </SeeAllLink>
      </Content>
    </Wrapper>
  )
}

export default Cannabis101
