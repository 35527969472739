import styled from 'styled-components'
import tw, { css } from 'twin.macro'

const Title = styled.h3`
  ${tw`w-full text-lg text-center uppercase text-black mb-12`}
  ${tw`md:(mb-20)`}
  ${css`
    letter-spacing: 0.5em;
  `}
  ${({ isWhite }) => isWhite && tw`text-white`}
`

export default Title
